import React from 'react'
import {TextInline, Grid, Col, Row, Title, ResponsiveImage, SubTitle, CardTitle, A, BRL} from '../theme'
import {margins} from "../theme/config.json";
import {logoLarge, logoSmall, MoreIcon, ExpandIcon, AddIcon, RemoveIcon, IconContainer} from '../theme/icons';
import ContextualMenu from './ContextualMenu'
import Button from './Button'
import Collapsible from './Collapsible'


export default ()=>{
    const xs = 12, md = 6, lg = 4;

    return (
    <Grid style={{paddingTop: margins.grid.top}}>
        <Row>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Logo's</Title>
                <ResponsiveImage src={logoLarge} />
                <br/><br/>
                <ResponsiveImage src={logoSmall} />
            </Col>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Collapsible</Title>
                <Collapsible title="allo" initialOpen> I am child </Collapsible>
            </Col>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Typography</Title>
                <br/><br/>
                <Title>
                    Passed Courses (11/23)
                </Title>
                <SubTitle>
                    Plan your BAMS courses, <br/> pieter.steyaert@upou.edu.ph
                </SubTitle>
                <CardTitle>
                    MMS 105 - Bla bla bla
                </CardTitle>
                <TextInline>
                    <i>
                    Trimester 2, <A href="">course page</A>
                    </i>
                    <BRL />
                    <b>Notes</b>
                    <br/>
                    This was a cool course
                    <BRL />
                    <b>Requirements</b>
                    <br/>
                    This course requires this and that
                </TextInline>
            </Col>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Buttons</Title>
                <Button text="login" onClick={()=>{alert('you clicked me')}} />
            </Col>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Icons</Title>
                <IconContainer>
                    <MoreIcon />
                </IconContainer>
                <IconContainer>
                    <ExpandIcon />
                </IconContainer>
                <IconContainer>
                    <AddIcon />
                </IconContainer>
                <IconContainer>
                <RemoveIcon />
                </IconContainer>
            </Col>
            <Col xs={xs} sm={xs} md={md} lg={lg}>
                <Title>Contextual Menu</Title>
                <ContextualMenu items={[
                    {
                        text: "Close",
                        action: ()=>{alert('should close')}
                    }, {
                        text: "Edit Notes",
                        action: ()=>{alert('should close')}
                    }, {
                        text: "Remove from passed",
                        action: ()=>{alert('should close')}
                    }, {
                        text: "Cancel",
                        action: ()=>{alert('should close')}
                    }, {
                        text: "Save",
                        action: ()=>{alert('should close')}
                    }
                ]} />
            </Col>
        </Row>
        <br/><br/><br/><br/><br/><br/>
    </Grid>)
}