import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import Collapsible from './Collapsible'
import LineInput from './LineInput'
import Button from './Button'
import {Wrapper, ContainerPadding, TextInline, Section} from '../theme'
import GoogleLogin from 'react-google-login';
import {googleAuth} from '../services/connector_settings.json'
import {Google} from '../theme/icons';

export default ()=>{
    const client = useSelector(state=>state.data.client);

    const responseGoogle = (response) => {
        client.login(response.profileObj.email)
    }

    if (!client){
        return null;
    } 

    return (
        <Collapsible title="login" noPadding noWrapper>
            <GoogleLogin
                clientId={googleAuth.clientId}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                    <Wrapper>
                        <ContainerPadding>
                            <Section style={{textAlign: "center", fontSize:"60px"}}>
                                <Google />
                            </Section>
                            <Button text="Log in with UPOU Google account" onClick={renderProps.onClick} />
                        </ContainerPadding>
                    </Wrapper>
                    
                )}
            />
        </Collapsible>
    )
}