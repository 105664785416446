import styled from 'styled-components';
import "../assets/circular-std";
import {colors, fonts, margins, medias, borders} from "./config.json";
import {Grid as Grid_, Col as Col_, Row as Row_} from 'react-styled-flexboxgrid';
import {ReactSVG} from 'react-svg';


//MAIN
// -- Grid
export const Grid = Grid_;
export const Row = Row_;
export const Col = styled(Col_)`
margin-bottom: ${props => props.marginBottom ? margins.div.general : null};
`;

export const Section = styled.div``


export const OnlySmall = styled.div`
  @media ${medias.onlysm} {
    display: inline;
  }
  display: none;
`
export const OnlyLarge = styled.div`
  @media ${medias.onlysm} {
    display: none;
  }
  display: inline;
`

export const MediumMax = styled.div`
  @media ${medias.mdandup} {
    display: inline;
  }
  display: none;
`
export const MediumMin = styled.div`
  @media ${medias.mdandup} {
    display: none;
  }
  display: inline;
`

export const Container = styled.div`
  margin: ${props => props.size? props.size : "30px"};
`;

export const ContainerPadding = styled.div`
  padding: ${props => props.size? props.size : "30px"};
  @media ${medias.onlysm} {
    padding: ${props => props.size? props.size : "5px"};
  }
`;

export const ContainerSides = styled(Container)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 38px;
  padding-right: 38px;
`;

export const CenterContainer = styled.div`
  position: relative;
  top: 50%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  transform: translateY(-50%);
`;

export const Smallbreak = styled.br`
  display: block;
  margin: 10px 0;
  content: " ";
`;

export const Wrapper = styled.section`
  border: ${borders.default};
  border-bottom: ${props=>props.noBottom?"0px":null};
  border-top: ${props=>props.noTop?"0px":null};
  border-right: ${props=>props.noRight?"0px":null};
  border-left: ${props=>props.noLeft?"0px":null};
`;


// -- Typo
export const Title = styled.h1`
  color: ${props => props.color ||  colors.textPrimary};
  font-size: ${fonts.size.title};
  text-align: ${props => props.align ||  fonts.align.title};
  font-weight: ${fonts.weight.title};
  text-transform: ${fonts.transform.title};
`;

export const TextInline = styled.div`
  color: ${props => props.color ||  colors.textPrimary};
  font-size: ${fonts.size.book};
  text-align: ${props => props.align ||  fonts.align.book};
  font-weight: ${fonts.weight.book};
  @media only screen and (max-width: ${medias.smp}px) {
    font-size: 16px;
    letter-spacing: auto;
  }
`;

export const Text = styled(TextInline)`
  padding-left: ${margins.text.left};
  padding-right: ${margins.text.right};
  padding-top: ${margins.text.top};
  padding-bottom: ${margins.text.top};
`;




export const ListItem = styled.li`
  font-size: ${fonts.size.listItem};
  text-align: ${props => props.align ||  fonts.align.listItem};
  font-weight: ${fonts.weight.listItem};
  text-transform: ${fonts.transform.listItem};
`;

export const SubTitle = styled.h2`
  font-size: 1.2em;
  text-align: center;
  color: grey;
  font-style: italic;
  font-weight: ${fonts.weight.bookW};
`;

export const CardTitle = styled.p`
  font-weight: ${fonts.weight.boldW};
  font-size: ${fonts.size.cardTitle};
  padding: 0px;
  margin: 0px;
  margin-bottom: 8px;
  transform: translateY(5px);
  @media only screen and (max-width: ${medias.smp}px) {
    font-size: 15px;
  }
`

export const A = styled.a`
  text-decoration: underline;
`

export const BRS = styled.br`
  font-size: 1em;
`

export const BRL = styled.div`
  height: 10px !important;
`


// -- Graphics

export const IconContainer = styled.div`
  display: inline-block;
  height: 100%;
  color: white;
  padding: 10px;
  background-color: black;
  width: 30px;
`;

export const Image = styled.img`
`;

export const ResponsiveImage = styled(Image)`
  width: 100% !important;
  transition: all .2s ease-in-out;
  height: ${props => props.height || null};
  object-fit: contain;
`;

export const ResponsiveImageWithEffect = styled(ResponsiveImage)`
&:hover {
  transform: scale(.9);
}
`;


export const Line = styled.hr`
  border-top: 3px solid black;
  margin: 0px;
  padding: 0px;
`;

export const Icon = styled(ReactSVG)`
  width: 25px;
  height: 25px;
  color: #000;
  @media only screen and (min-width: ${medias.smp}px) {
    width: 20px;
    height: 20px;
  }
`;

export const SquareImage = styled.div`
  background: url(${props => props.img}) no-repeat center center;
  background-size: cover;
  border-bottom: ${props => props.borderBottom ? "2px solid black" : "none"};
  display: inline-block;
  width: ${props => props.width ||  '50%'};
  padding-bottom: ${props => props.width ||  '50%'};
  transition: all .2s ease-in-out;
`

export const SquareImage2 = styled.td`
  height: ${props => props.width};
  width: ${props => props.width};
  background-image: url(${props => props.image})
  background-size: 100% auto;
  //background-size: cover;
  // background-repeat: no-repeat;
  // background-size: 50px 50px;
`

export const SquareImageWithEffect = styled(SquareImage)`
&:hover {
  transform: scale(.9);
}
`;

//MemberList
export const CircleImage = styled(Image)`
  width: 100% !important;
  border-radius: 50%;
  transition: all .2s ease-in-out;
`;

export const CircleImageWithEffect = styled(CircleImage)`
&:hover {
  transform: scale(.9);
}
`;

export const ClickIcon = styled(Icon)`
  :hover {
    cursor: pointer
  }
`;


