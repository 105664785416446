import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import Collapsible from './Collapsible'
import LineInput from './LineInput'
import Button, {Select} from './Button'
import {Wrapper, ContainerPadding, TextInline, Section} from '../theme'
import {Google} from '../theme/icons';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';
import {googleAuth} from '../services/connector_settings.json'

export const STATES = Object.freeze({
    INIT: Symbol("data/client"),
    NEXT: Symbol('data/student'),
});


export default ()=>{
    const [state, setState] = useState(STATES.INIT);
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [year, setYear] = useState(2007);
    const [term, setTerm] = useState(0);
    const [legacy, setLegacy] = useState(false);
    const [studentNumber, setStudentNumber] = useState('')
    const [busy, setBusy] = useState(false);
    const client = useSelector(state=>state.data.client);
    const width = "110px";

    const responseGoogle = (response) => {
        console.log(response);
        

        let profile = response.profileObj;
        if (client.checkEmail(profile.email)){
            setEmail(profile.email);
            setFirstname(profile.givenName);
            setLastname(profile.familyName);
            setState(STATES.NEXT);
        } else {
            // do nothing?
        }
    }    

    const register = ()=>{
        setBusy(true);
        client.register(email, firstname, lastname, parseInt(year), parseInt(term), studentNumber, legacy==="true").then(()=>{
            setBusy(false);
        }).catch(()=>{setBusy(false)})
    }

    const getYears = () => {
        const year = 2007;
        return (
          Array.from( new Array(new Date().getFullYear() - year + 2), (v,i) =>
            <option key={i} value={year+i}>{year+i}</option>
          )
        );
      };

    const getTerms = ()=>Array.from(new Array(3), (v, i) => <option key={i} value={i}>term {i+1}</option>)
    const getLegacy = ()=>["Regular", "Legacy (old)"].map((text, i)=><option key={i} value={i==1}>{text}</option>)

    const getInitContent = ()=>(
        <Section>
            <GoogleLogin
                clientId={googleAuth.clientId}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                    <Wrapper>
                        <ContainerPadding>
                            <Section style={{textAlign: "center", fontSize:"60px"}}>
                                <Google />
                            </Section>
                            <Button text="Connect with UPOU Google account" onClick={renderProps.onClick} />
                        </ContainerPadding>
                    </Wrapper>
                    
                )}
            />
        </Section>
    )

    const getNextContent = ()=>(<Section>
        <LineInput value={firstname} setValue={setFirstname} title="first name" width={width} noBottom noTop/>
                <LineInput value={lastname} setValue={setLastname} title="last name" width={width} noBottom/>
                <LineInput value={email} setValue={()=>{}} title="email" width={width} noBottom/>
                <LineInput value={studentNumber} setValue={setStudentNumber} title="student number" width={width} noBottom/>
                <Wrapper>
                    <ContainerPadding size={"10px"}>
                        <TextInline>
                            <b style={{width: "500px"}}>
                                Year first enrolled
                            </b>
                            <Select onChange={e => setYear(e.target.value)}>
                            {getYears()}
                        </Select>
                        </TextInline>
                    </ContainerPadding>
                </Wrapper>
                <Wrapper noTop noBottom>
                    <ContainerPadding size={"10px"}>
                        <TextInline>
                            <b  style={{width: "500px"}}>
                                Term first enrolled
                            </b>
                            <Select onChange={e => setTerm(e.target.value)}>
                            {getTerms()}
                        </Select>
                        </TextInline>
                    </ContainerPadding>
                </Wrapper>
                <Wrapper noBottom>
                    <ContainerPadding size="10px">
                        <TextInline>
                            <b  style={{width: "500px"}}>
                                Curriculum&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </b>
                            <Select onChange={e => setLegacy(e.target.value)}>
                            {getLegacy()}
                        </Select>
                        </TextInline>
                    </ContainerPadding>
                </Wrapper>
                
                {busy? <Button text="loading" onClick={()=>{}}/> : <Button text="submit" onClick={register} noBottom /> }
    </Section>)

    if (!client){
        return null;
    } else {
        return (
            <Collapsible title="register" noPadding noWrapper>
                {state == STATES.INIT? getInitContent():getNextContent()}
            </Collapsible>
        )
    }
}
