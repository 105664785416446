import React from 'react'
import {Col, Row, Title, BRL, Section, Wrapper, ContainerPadding, OnlySmall} from '../theme'
import {useSelector} from 'react-redux'
import styled from 'styled-components';
import {medias} from '../theme/config.json'

const Selecter = styled.td`
    min-width: 10px;
    background-color: ${props=>props.go?props.color:null};
`



export const OnlyLarge = styled.tr`
  @media ${medias.onlysm} {
    display: none;
  }
`

const FirstSmall = styled.td`
@media ${medias.onlysm} {
    max-width: 10px;
  }
  width: 20px;
`


const Report = ()=>{
    const courseData = useSelector(state=>state.data.courseData);
    const settings = useSelector(state=>state.data.settings);
    return (
        <Section style={{background: "#DCDCDC"}}>
            {(courseData && settings)? 
                <Wrapper>
                    <ContainerPadding>
                        <Title>Report</Title>
                        <BRL />
                        <table >
                            <tbody>
                            <tr>
                                <td>
                                    Passed courses
                                </td>
                                <td>
                                    {`${courseData.getCredits().passedCourses}`}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Passed credits
                                </td>
                                <td>
                                {`${courseData.getCredits().passed} / ${settings.creditsRequired}`}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Planned courses
                                </td>
                                <td>
                                {`${courseData.getCredits().plannedCourses}`}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <BRL /><BRL /><BRL /><BRL /><BRL /><BRL />

                        <OnlySmall>
                            <table>
                                <tbody>
                                <td>
                                        Passed :
                                    </td>
                                    <td>
                                        <span style={{backgroundColor: "#00563F"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </td>
                                    <td>
                                    Planned :
                                    </td>
                                    <td>
                                        <span style={{backgroundColor: "#FDB525"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </td>
                                    <td>
                                    Missing :
                                    </td>
                                    <td>
                                        <span style={{backgroundColor: "#8E1837"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </td>
                                </tbody>
                            </table>
                            </OnlySmall>

                        <table style={{width: "100% !important"}}>
                            
                        <tbody>
                            <OnlyLarge>
                                <td></td>
                                <td></td>
                                <td style={{textAlign: "center"}}>passed</td>
                                <td style={{textAlign: "center"}}>planned</td>
                                <td style={{textAlign: "center"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            </OnlyLarge>

                        {courseData.sectionMap(([name, data])=>{
                            const total = data.courses.length;
                            const req = data.amount_required;
                            let required = 'all';
                            const planned = data.courses.filter(c=>c.planned).length;
                            const passed = data.courses.filter(c=>c.passed).length;
                            const missing = Math.max(req - (planned+passed), 0);
                            if (req !== total){
                                required = `${req} / ${total}`;
                            } 
                            return (
                            [<tr xs={12} key={name}>
                                <td>
                                    <b>{name}</b>  {`${required} required, ${planned} planned, ${passed} passed, ${missing} missing`}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            ,<tr style={{lineHeight: "0px"}} >&nbsp;</tr>,
                                data.courses.map(({name, passed, planned}, index, courses)=>(
                                    <tr key={index} style={{ border: "solid black 3px", background: "white"}}>
                                            <td >
                                                {name}    
                                            </td>
                                            
                                            <Selecter go={false} color="white">
                                                
                                            </Selecter>
                                            <Selecter go={passed} color="#00563F">
                                                
                                            </Selecter>
                                            <Selecter go={planned && !passed} color="#FDB525">
                                                
                                            </Selecter>
                                            <Selecter go={!passed && !planned} color={data.courses.length === data.amount_required? "#8E1837" : "grey"}>
                                                
                                            </Selecter>
                                        </tr>
                                ))
                                ,<tr>&nbsp;</tr>
                                ,<tr>&nbsp;</tr>
                            ]
                            
                        )})}
                        </tbody>
                        </table>
                        
                    </ContainerPadding>
                </Wrapper>
                        : null}
            
        </Section>
    )
}

export default Report;