export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_STUDENT: Symbol('data/student'),
    SET_DATA: Symbol('data/data'),
    SET_SETTINGS: Symbol('data/settings')
});

const initialState = {
  client: null,
  student: null,
  courseData: null,
  settings: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_STUDENT: {
      return {
        ...state,
        student: action.student
      }
    }
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        courseData: action.courseData
      }
    }
    case ACTIONS.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings
      }
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
