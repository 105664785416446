import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import {colors} from "../theme/config.json";
//import back from '../../assets/icons/back-sketch.svg'; //change icon


const Background = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(${props=>props.angle}, ${colors.tertiary}, ${colors.primary});
    z-index: -100;
`

export default ()=>{
    return <Background angle={"180deg"} />
}