
import React from 'react'
import {Col, Row, Title, Wrapper, ContainerPadding, Section} from '../theme'
import {useSelector} from 'react-redux'

const Warning = ({print})=>{
    const courseData = useSelector(state=>state.data.courseData);

    

    return (courseData && courseData.errors.length>0)?
            print?
            <Section>
                <Title>Warning</Title>
                {courseData.errors.map((error, idx)=><Wrapper key={idx} noBottom={idx<(courseData.errors.length-1)}><ContainerPadding size='7px' style={{color: "#8E1837"}}>{error}</ContainerPadding></Wrapper>)}
            </Section>
            : <Row center="xs">
                <Col xs={12} md={6}>
                    <Title>Warning</Title>
                    {courseData.errors.map((error, idx)=><Wrapper key={idx} noBottom={idx<(courseData.errors.length-1)}><ContainerPadding size='7px' style={{color: "#8E1837"}}>{error}</ContainerPadding></Wrapper>)}
                </Col>
            </Row>
            :null
    
}

export default Warning;