import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import toast from '../services/toast'

const courseData = (dispatch)=>{
    let [sections, count, errors] = [new Map(), 0, []];

    const init = (noteList, courseList, passedCourses, plannedCourses)=>{
        

        const notes =  noteList.reduce((map, item) => {
            map.set(item.course._ref, item);
            return map
          }, new Map())

        const passed =  passedCourses.reduce((map, item) => {
            map.set(item._id, item);
            return map }, new Map())
        
        const planned =  plannedCourses.reduce((map, item) => {
            map.set(item.course._ref, item);
            return map
            }, new Map())


        sections.clear();
        courseList.forEach((course)=>{
            if (notes.has(course._id)){
                course.note = notes.get(course._id);
            }

            if (planned.has(course._id)){
                course.planned = planned.get(course._id);
            } else {
                course.planned = false;
            }

            course.passed = passed.has(course._id);


            const sectionname = course.section.name;
            if (sections.has(sectionname)){
                sections.get(sectionname).courses.push(course);
            } else {
                sections.set(sectionname, {
                    amount_required: course.section.amount_required,
                    courses: [course]
                });
            }
        })

        // const getNumber = (name)=>{
        //     return name.replace( /(^.+)(\w\d+\w)(.+$)/i,'$2'); //=> '123'
        // }

        sections.forEach(section=>{
            section.courses.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
        })

        sections = new Map([...sections.entries()].sort((a, b)=>{ 
            return (a[0].toUpperCase() > b[0].toUpperCase())?1:-1}));
        publish();
    }

    const getAvailableForPlanning = ()=>{
        let trimesterData = {
            trimester1 : [],
            trimester2 : [],
            trimester3 : []
        }

        let planning = {};

        sections.forEach(section=>{
            section.courses.forEach(course=>{
                course.trimester.forEach(({name})=>{
                    if (!course.passed && !course.planned){
                        trimesterData[name.replace(/ /g, '')].push(course)
                    }  
                })

                if (!course.passed && course.planned){
                    if (planning[course.planned.year]){
                        planning[course.planned.year].push(course);
                    } else {
                        planning[course.planned.year] = [course];
                    }
                }
            })
        })
        
        return {trimesterData, planning};
    }

    const checkForErrors = ()=>{
        errors.length = 0;
        let shouldDoubleWarn = false;

        for (let [name, section] of sections) {
            const req = section.amount_required;
            const planned = section.courses.filter(c=>c.planned).length;
            const passed = section.courses.filter(c=>c.passed).length;

            if (planned + passed > req){
                errors.push(` You have planned ${planned} courses and passed ${passed} courses for ${name}, which is more than the required amount of ${req}`);
            }
            // eslint-disable-next-line no-loop-func
            section.courses.forEach(course=>{
                if (course.passed && !canAddToPassed(course._id)){
                    errors.push(`${course.name} was marked as passed without fulfilling one or more of its requirements`);
                }

                if (course.planned && !canAddToPlanned(course._id, course.planned.year, course.planned.trimester)){
                    errors.push(`${course.name} is missing planned requirements`);
                    shouldDoubleWarn = true;
                }
            })
        }

        let planning = getAvailableForPlanning().planning;


        for (const [year, value] of Object.entries(planning)) {
            let terms = [0,0,0];
            const maxCredits = 13;

            value.forEach((course, index)=>{
                terms[course.planned.trimester]+=course.credits;
            })

            if (terms[0] > maxCredits){
                errors.push(`Year ${year}-${parseInt(year)+1} in term 1 has more than ${maxCredits} credits planned, which is not advisable`);
            }

            if (terms[1] > maxCredits){
                errors.push(`Year ${year}-${parseInt(year)+1} in term 2 has more than ${maxCredits} credits planned, which is not advisable`);
            }

            if (terms[2] > maxCredits){
                errors.push(`Year ${year}-${parseInt(year)+1} in term 3 has more than ${maxCredits} credits planned, which is not advisable`);
            }
          }

        if (shouldDoubleWarn){
            toast('There is a problem with your current plan. Please check the warning box');
        }
    }

    const getCredits = (before)=>{
        let total = 0;
        let passed = 0;
        let totalCourses = 0;
        let passedCourses = 0;
        let plannedCourses = 0;
        let plannedCredits = 0;

        sections.forEach(section=>{

            section.courses.forEach((course)=>{
                total+= course.credits;
                passed+=course.passed?course.credits:0;
                passedCourses+=course.passed?1:0;
                plannedCourses+=course.planned?1:0;
                totalCourses+=1;
                if (before && course.planned){
                    if (course.planned.year < before.year || (course.planned.year === before.year && course.planned.trimester < before.trimester)){
                        plannedCredits+=course.credits;
                    } 
                } else {
                    plannedCredits+=course.planned?course.credits:0;
                }
            });
        });


        return {total, passed, totalCourses, passedCourses, plannedCourses, plannedCredits, remaining: totalCourses-(passedCourses+plannedCourses)}
    }

    const canAddToPassed = (courseid)=>{
        let course = getCourse(courseid);
        let rv = true;
        if (course){
            if (course.requirements && course.requirements.length > 0){
                course.requirements.forEach(req=>{
                    let reqcourse = getCourse(req._id);
                    if (!reqcourse || !reqcourse.passed){
                        rv = false;
                    }
                })
            } else if (course.credit_requirements) {
                if (getCredits().passed < course.credit_requirements){
                    rv = false;
                }
            }
        } else {
            rv = false;
        }
        return rv;
    }

    const canAddToPlanned = (courseid, year, trimester)=>{
        let course = getCourse(courseid);
        let rv = true;
        if (course){
            if (course.requirements && course.requirements.length > 0){
                course.requirements.forEach(req=>{
                    let reqcourse = getCourse(req._id);
                    if (!reqcourse || (!reqcourse.passed && !reqcourse.planned)){
                        rv = false;
                    } else if (reqcourse.planned){
                        if (reqcourse.planned.year > year){
                            rv = false;
                        } else if (reqcourse.planned.year === year && reqcourse.planned.trimester >= trimester){
                            rv = false;
                        }
                    }
                })
            } else if (course.credit_requirements) {
                const credits = getCredits({year, trimester});
                if ((credits.plannedCredits+credits.passed) < course.credit_requirements){
                    rv = false;
                }
            } 
        }
        return rv;
    }

    const getCourse = (courseid)=>{
        let rv = null;
        sections.forEach(section=>{
            section.courses.forEach(course=>{
                if (courseid === course._id){
                    rv = course;
                }
            })
        })
        return rv;
    }

    const editNote = (content, courseid)=>{
        let course = getCourse(courseid);
        if (course){
            course.note = course.note || {};
            course.note.content = content;
            publish();
        } else {
            console.log("did not find")
        }
    }

    const setPassed = (value, courseid)=>{
        let course = getCourse(courseid);
        if (course){
            course.passed = value;
            publish();
        }
    }

    const plan = (courseid, year, trimester)=>{
        let course = getCourse(courseid);
        if (course){
            course.planned = {year, trimester};
        }
        publish();
    }

    const unplan = (courseid)=>{
        let course = getCourse(courseid);
        if (course){
            course.planned = false;
        }
        publish();
    }

    const getNote = (courseid)=>{
        let course = getCourse(courseid);
        if (course && course.note){
            return course.note.content;
        } else {
            return null;
        }
    }

    const sectionMap = (f)=>{
        return Array.from(sections).map(f);
    }

    const publish = ()=>{
        checkForErrors();
        dispatch({type: DATA_ACTIONS.SET_DATA, courseData:{...share, count: count++}});
    }

    const share = {
        init, sections, sectionMap, publish, editNote, getNote, setPassed, canAddToPassed, errors, getAvailableForPlanning, plan, unplan, canAddToPlanned, getCredits
    }


    return share;
}


export default courseData;
