import React, {useEffect} from 'react';
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Store from "../Store";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sanityclient, { client } from '../services/client';
import { css } from 'glamor';
import '../style.css';
import Home from './Home'
import Background from './Background'
import Development from './Development'
import courseData from '../services/courseData'
import PrintPage from './PrintPage'

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      toast.configure()
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } else if (data.client && dispatch && data.student && !data.courseData){
      data.client.loadUserData(courseData(dispatch), data.student);
    }
  })
  return (
    null
  )
}

const Toaster = ()=>{
  const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
    <i onClick={closeToast}></i>
  );
  return <ToastContainer
    toastClassName={css({color:"black", border: "solid black 2px"})}
    closeButton={<CloseButton/>}
  />
}

const history = createBrowserHistory()

const App =()=>{
  return (
    <Provider store={Store}>
    <DataHelper />
    <Toaster />
    <Background />
      <Router history={history}>
        <span>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/print" component={PrintPage} />
            <Route exact path="/dev" component={Development} />
            <Route component={Home}/>
          </Switch>
        </span>
      </Router>
  </Provider>
  );
}

export default App;
