import React, {useState, useEffect} from 'react'
import Button from './Button'
import {Wrapper, Section, ContainerPadding, Line} from '../theme'
import Expand from 'react-expand-animated';


export default ({children, title , initialOpen, noPadding, trigger, noWrapper, titleComponent, secondText})=>{
    const [open, setOpen] = useState(initialOpen);
    const [transit, setTransit] = useState(false);
    const duration = 400;
    trigger = trigger || {};

    trigger.exec = (val)=>setOpen(val);

    const toggle = ()=>{
        setOpen(!open);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
    }

    const getInner = ()=>(noPadding ? <Section>{children}</Section> : <ContainerPadding>{children}</ContainerPadding>);

    return (
    <Section>
        {titleComponent?<Section onClick={toggle}>{titleComponent}</Section> : <Button text={title} onClick={toggle} secondText={secondText} />}
        <Expand open={open} duration={duration}>
            {noWrapper? getInner() : <Wrapper noTop>{getInner()}</Wrapper>}
        </Expand>
        {transit?<Line />:null}
    </Section>)
}