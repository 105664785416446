import React, {useState} from 'react'
import styled from 'styled-components';
import {Text} from '../theme'

const Input = styled.input`
width: 100%;
padding: 5px;
box-sizing: border-box;
font-size: 15px;
`

export default ({title, noBottom, noTop, noLeft, noRight, value, setValue, width, password})=>{
    title = title || ''

    return <table style={{border: "solid black 3px", width: "100%", 
                    borderBottom: noBottom?"0px":"solid black 3px", 
                    borderTop: noTop?"0px":"solid black 3px", 
                    borderLeft: noLeft?"0px":"solid black 3px", 
                    borderRight: noRight?"0px":"solid black 3px", 
                    tableLayout: "fixed"}}>
    <tr>
        <td style={{width: width, borderRight: "solid black 3px"}}>
            <Text>
                <b>{title}</b>
            </Text>
        </td>
        <td style={{width: '10px'}}> &nbsp;&nbsp; </td>
        <td style={{width: "70%"}}>
            <Input style={{border: "0px"}} type={password?"password":"email"} name="name" value={value} onChange = {e => setValue(e.target.value)} />
        </td>
        <td> &nbsp;&nbsp; </td>
    </tr>
</table>
}