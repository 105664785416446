import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {colors, fonts, margins, medias} from "../theme/config.json";
import {InlineText} from "../theme";

const settings = {
    borderline: "3",
    containerpadding: "6"
}

export const ButtonContainer = styled.span`
  display:table;
  width: ${props => props.inline ? 'auto !important' : '100% !important'};
`;
 
export const ButtonCenter = styled.div`
  padding: ${settings.containerpadding}px;
  background-color:  ${props=>props.invert?colors.tertiary:colors.secondary}; 
  padding-top: ${settings.containerpadding*1}px;
  padding-bottom: ${settings.containerpadding*1}px;
  padding-left: 15px;
  margin-left: ${margins.text.left};
  margin-top: ${margins.text.top};
  text-align: left;
  display:table-cell;
  text-transform: ${fonts.transform.title};
  color: ${props=>props.invert?colors.secondary:colors.tertiary}; 
  font-weight: ${fonts.weight.boldW};
  letter-spacing: 2px;
  font-size: 25px;
  border: ${props=>props.invert?null:"3px solid "+colors.secondary}; 
  border-top: ${props=>props.noTop?"0px":null};

  @media only screen and (max-width: ${medias.smp}px) {
    font-size: 15px;
    letter-spacing: auto;
  }

  &:hover {
    background-color: ${props=>props.noHover?null:colors.tertiary};
    color: ${props=>props.noHover?null:colors.secondary};
    cursor: ${props=>props.noHover?null:"pointer"} pointer;
    border: ${props=>props.noHover?null:"3px solid black"} ;
    
  }
`;

export const Select = styled.select`
padding: ${settings.containerpadding}px;
background-color:  ${props=>props.invert?colors.tertiary:colors.secondary}; 
padding-top: ${settings.containerpadding*1}px;
padding-bottom: ${settings.containerpadding*1}px;
margin-left: ${margins.text.left};
margin-top: ${margins.text.top};
text-align: left;
display:table-cell;
text-transform: ${fonts.transform.title};
color: ${props=>props.invert?colors.secondary:colors.tertiary}; 
font-weight: ${fonts.weight.boldW};
letter-spacing: 2px;
font-size: 25px;
border: ${props=>props.invert?null:"3px solid "+colors.secondary}; 
border-top: ${props=>props.noTop?"0px":null};

@media only screen and (max-width: ${medias.smp}px) {
  font-size: 15px;
  letter-spacing: auto;
}

&:hover {
  background-color: ${props=>props.noHover?null:colors.tertiary};
  color: ${props=>props.noHover?null:colors.secondary};
  cursor: ${props=>props.noHover?null:"pointer"} pointer;
  border: ${props=>props.noHover?null:"3px solid black"} ;
  
}
`

const Button = ({ inline, link, text, onClick, options, component, secondText, noHover, invert, noTop}) => {
    options = options || {};
    let hideTop = noTop || options.noTop;
    let hideBottom = options.noBottom;
    let hideSides = options.noSides;

    let renderInner = (
            <ButtonCenter noTop={hideTop} noBottom={hideBottom} invert={invert} noHover={noHover} >
                {component?component():<span dangerouslySetInnerHTML={{ __html: text}} />}
                {secondText?<span style={{textAlign: "right", float: "right"}}>{secondText}</span>:null}
            </ButtonCenter>
    )


    if (link) {
        if (options.external){
            return (
                <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                    <ButtonContainer inline = {inline? true : false}>
                    {renderInner}
                    </ButtonContainer>
                </a>
            )
        } else {
            return (
                <Link to={link}>
                    <ButtonContainer inline = {inline? true : false}>
                    {renderInner}
                    </ButtonContainer>
                </Link>
            )
        }
    } else if (onClick) {
        return (
            <ButtonContainer inline = {inline? true : false} onClick={onClick}>
                {renderInner}
            </ButtonContainer>
        )
    } else {
        return (
            <h1>this is probs not a button</h1>
        )
    }
}

export default Button