import React from 'react'
import styled from 'styled-components';
import {Section, TextInline, Wrapper} from '../theme'
import {fonts, colors} from "../theme/config.json";

export const ContextualMenuText = styled(TextInline)`
    font-weight: ${fonts.weight.boldW};
    padding-left: 5px;
    padding-right: 5px;
    &:hover {
        background: ${colors.secondary};
        color: ${colors.primary};
    }
    cursor: pointer;

`

export default ({items})=>{
    return items.map((item, index)=>(
        <Wrapper noBottom={index<items.length-1} style={{width: "200px"}}>
            <ContextualMenuText key={index}>
                    {item.text}
                </ContextualMenuText>
        </Wrapper>))
}