import React from 'react'
import styled from 'styled-components';
import {Section, ResponsiveImage, Grid, Row, Col} from '../theme'

const FooterContainer = styled.div`
    width: 100%;
    text-align: center;
`

const Footer = ()=>(
    <FooterContainer>
        <br/><br/><br/>
        
            <Row center="xs">
                <Col xs={2} lg={1}>
                    <ResponsiveImage src="https://cdn.sanity.io/images/t39t9igo/production/30d322a87dffa9955cc0ee2ae54245aa81f67d65-552x552.png" />
                    <br/><br/>
                </Col>
                <Col xs={4} lg={2}>
                    <img style={{width: "80%"}} src="https://networks.upou.edu.ph/wp-content/uploads/2017/02/UPOU-seal-no-background_sakto.png" />
                    <br/><br/><br/>
                </Col>
                <Col xs={12}>
                    Copyright © 2021 Planner / UPOU - Made with ♥ by Studio Subtiv powered by <a href="https://www.seads.network">SEADS</a>
                    <br/><br/>
                </Col>
            </Row>
        
    </FooterContainer>
)

export default Footer;