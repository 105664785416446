import React from 'react'
import {FiSend, FiEdit} from 'react-icons/fi'
import {IoMdClose, IoMdMore} from 'react-icons/io'
import {MdNavigateNext, MdNavigateBefore, MdRemoveCircle} from 'react-icons/md'
import {AiOutlineGoogle, AiOutlineExpandAlt} from 'react-icons/ai';
import {GrFormAdd} from 'react-icons/gr';
import styled from 'styled-components';
import logoL from '../assets/icons/planner-l.svg';
import logoS from '../assets/icons/planner-s.svg';
import {borders} from "../theme/config.json";


export const containerWidth = "50";

export const IconContainer = styled.div`
    width: ${containerWidth}px;
    height: 40px;
    font-size: 30px;
    text-align: center;
    padding-top: 10px;
    border: ${borders.default};
    border-bottom: ${props=>props.noBottom?"0px":null};
    border-top: ${props=>props.noTop?"0px":null};
    border-right: ${props=>props.noRight?"0px":null};
    border-left: ${props=>props.noLeft?"0px":null};
`

export const ContaineredIcon = ({icon, noBottom, noTop, noRight, noLeft})=>{
    return <IconContainer noBottom={noBottom} noTop={noTop} noRight={noRight} noLeft={noLeft}>{icon}</IconContainer>
}

export const SendIcon = FiSend;
export const CloseIcon = IoMdClose;
export const NextIcon = MdNavigateNext;
export const PrevIcon = MdNavigateBefore;
export const EditIcon = FiEdit;
export const MoreIcon = IoMdMore;
export const ExpandIcon = AiOutlineExpandAlt;
export const AddIcon = GrFormAdd;
export const RemoveIcon = MdRemoveCircle;
export const Google = AiOutlineGoogle;

export const logoLarge = logoL;
export const logoSmall = logoS;



