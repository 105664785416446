import React, {useState} from 'react'
import {Grid, Col, Row, Title, ResponsiveImage, SubTitle, Text, BRL, BRS, OnlySmall, OnlyLarge, Section, Wrapper, ContainerPadding, TextInline} from '../theme'
import {margins} from "../theme/config.json";
import {logoLarge, logoSmall} from '../theme/icons';
import Button from './Button'
import Login from './Login'
import Register from './Register'
import ChangePassword from './ChangePassword'
import {useSelector} from 'react-redux'
import {CardList} from './Card'
import Planning from './Planning'
import Report from './Report'
import Warning from './Warning'
import toast from '../services/toast'
import Footer from './Footer'

/**
 * v sort sections based on appearance name / title
 * v implement testing on unit points
 * v Button: left align on Sections title + right align progress
 * v Add button / collectible under trimesters called planned/ only trimester is collapsible / others are buttons 
 *              make inverted button / also should not be hoverable
 * v Add view underneath that shows three column progress : passed, planned, missing
 * 
 */

//client ID 850829466805-ugsb0lo3uuh5ba90j6c74m330l5mv36r.apps.googleusercontent.com
//pb_PJUqR9Yb5DyHKwmbBrJAe public secret




export default ()=>{

    const client = useSelector(state=>state.data.client);
    const student = useSelector(state=>state.data.student);
    const courseData = useSelector(state=>state.data.courseData);
    const [busy, setBusy] = useState(false);

    const help = <Button text="help" link="http://upou-bams-test.freshdesk.com/support/solutions/articles/43000576363" options={{external:true}}/>

    const buttons = (()=>{
        if (!student){
            return (<Row center="xs">
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <Login />
                        <BRL />
                        <Register />
                        <BRL />
                        {help}
                    </Col>
                    </Row>)
        } else {
            return <Row center="xs" key="1">
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <Button text="logout" onClick={()=>{client.logout()}} />
                            <BRL />
                            {help}
                            <BRL />
                            {busy?<Button text=">>saving" onClick={()=>{toast('please wait, we are still saving')}} />
                            :<Button text="save" onClick={()=>{
                                setBusy(true);
                                client.save(student, courseData.sections).then(()=>{toast('data saved');setBusy(false)});
                                }} 
                                />}
                            <BRL />
                            <Button text="print" link="/print" />
                        </Col>
                    </Row>
        }
    })()

    if (!client){
        return null;
    } else if (!student && client.environment.dev){
        client.login('pieter@seads.network');
    }

    return <Grid style={{paddingTop: margins.grid.top}}>
        <Row center="xs">
            <Col xs={8}>
                <OnlySmall>
                    <ResponsiveImage src={logoSmall} />
                </OnlySmall>
                <OnlyLarge>
                    <ResponsiveImage src={logoLarge} />
                </OnlyLarge>
            </Col>
        </Row>
        <BRL />
        <Row>
            <Col xs={12}>
                <SubTitle>
                    Plan your BAMS courses 
                    {student? <span>, <Section>{`${student.firstname} ${student.lastname}`}</Section></span>:null}
                </SubTitle>
            </Col>
        </Row>
        <BRL /><BRL /><BRL />
        {buttons}
        <BRL /><BRL /><BRL /><BRL /><BRL /><BRL />
        <Warning />
        <BRL /><BRL /><BRL /><BRL /><BRL /><BRL />
        <Row>
            <Col xs={12} md={6}>
                {student?
                <Section>
                <Title> Passed 
                < br/> <i style={{fontSize:" .8em"}}>or waiting to be graded</i>
                    </Title> 
                    {courseData? 
                        courseData.sectionMap(([name, data])=>(
                            <CardList passed={true} name={name} key={name} courses={data.courses} required={data.amount_required}/>
                        ))
                    : null}
                <Title> not yet taken </Title>
                    {courseData? 
                        courseData.sectionMap(([name, data])=>(
                            <CardList passed={false} name={name} key={name} courses={data.courses} />
                        ))
                    : null}
                </Section> : null }
            </Col>
            <Col xs={12} md={6}>
                {(student && courseData)? <Planning planningData={courseData.getAvailableForPlanning().trimesterData} plannedData={courseData.getAvailableForPlanning().planning} /> : null}
            </Col>
        </Row>
        <BRL /><BRL /><BRL /><BRL /><BRL /><BRL />
        {student?<Report />:null}
        <BRL /><BRL /><BRL /><BRL /><BRL /><BRL />
        <Footer />
    </Grid>
}