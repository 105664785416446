import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Grid, Col, Row, Title, ResponsiveImage, SubTitle, BRL, OnlySmall, OnlyLarge, Section, Wrapper, ContainerPadding, TextInline} from '../theme'
import {logoLarge, logoSmall, MoreIcon, ExpandIcon, AddIcon, RemoveIcon, ContaineredIcon, containerWidth} from '../theme/icons';
import Collapsible from './Collapsible'
import Button from './Button'

const Planning = ({planningData, plannedData})=>{
    
    const [years, setYears] = useState(3);
    const courseData = useSelector(state=>state.data.courseData);

    const thisYear = (()=>{
        return parseInt((new Date()).getFullYear().toString().substr(-2)-1);
    })()

    const getYearsContent = ()=>{

        if (planningData){
            const rendered = [];

            for (let currentYear = thisYear; currentYear < thisYear+years; currentYear++) {
                for (let currentTrimester = 0; currentTrimester < 3; currentTrimester++){
                    let creditCounter = 0;

                    if (plannedData[currentYear] && plannedData[currentYear].length>0){
                        plannedData[currentYear].filter(course=>course.planned.trimester===currentTrimester)
                        .map((course, index, courses)=>{
                            creditCounter+=course.credits;
                        })
                    }

                    const name = `Trimester ${currentTrimester+1} | ${currentYear}-${currentYear+1}`
                    rendered.push(<Section key={name} >
                        <Collapsible title={name} secondText={`credits: ${creditCounter}`} noPadding noWrapper >
                            <Wrapper noTop>
                            <Button text="Planned" noPadding noWrapper onClick={()=>{}} noHover invert noTop/>
                            { (plannedData[currentYear] && plannedData[currentYear].length>0) ?
                                plannedData[currentYear].filter(course=>course.planned.trimester===currentTrimester)
                                .map((course, index, courses)=>{
                                        creditCounter += course.credits;
                                        return(
                                        <Wrapper key={index+course.name} noBottom={index<courses.length-1} noLeft={true} noRight={true} >
                                            <Row>
                                                <Col xs={10}>
                                                    <ContainerPadding size='7px'>
                                                        <TextInline>
                                                            {course.name}
                                                        </TextInline>
                                                    </ContainerPadding>
                                                </Col>
                                                <Col xs={2} onClick={()=>{courseData.unplan(course._id)}}>
                                                    <ContaineredIcon style={{float: "left"}} icon={<RemoveIcon />} noTop={true} noRight={true} noLeft={true} noBottom={true}/>
                                                </Col>
                                                </Row>
                                        </Wrapper>        
                                    )}
                                )
                                : <Wrapper noLeft noRight>
                                    <ContainerPadding>
                                        <TextInline>
                                            No course has been selected for this trimester so far
                                        </TextInline>
                                    </ContainerPadding>
                                </Wrapper>
                            }
                        <Button text="Offered" noPadding noWrapper onClick={()=>{}} noHover invert/>
                            {planningData[`trimester${currentTrimester+1}`]
                            .filter((course)=>{
                                return courseData.canAddToPlanned(course._id, currentYear, currentTrimester)
                            })
                            .map((course, index, courses)=>{
                                    return <Wrapper key={index} noBottom={true} noLeft={true} noRight={true}  >
                                    <Row>
                                        <Col xs={10}>
                                            <ContainerPadding size='7px'>
                                                <TextInline>
                                                    {course.name}
                                                </TextInline>
                                            </ContainerPadding>
                                        </Col>
                                        <Col xs={2} onClick={()=>{courseData.plan(course._id, currentYear, currentTrimester)}}>
                                            <ContaineredIcon style={{float: "left"}} icon={<AddIcon />} noTop={true} noRight={true} noLeft={true} noBottom={true}/>
                                        </Col>
                                    </Row>
                                    </Wrapper>
                            })}
                        </Wrapper>
                        </Collapsible>
                        
                        <BRL /><BRL /><BRL />
                    </Section> )
                }
            }
            return rendered;
        } else {
            return null;
        }
    }
    


    return <Section>
        <Title>Future plan of study
            <br/> <i style={{fontSize:" .8em"}}>&nbsp;</i>
        </Title>
        
        {getYearsContent()}
        <Button text="add year" onClick={()=>setYears(years+1)} />
    </Section>
}

export default Planning;